import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MdArrowBack, MdArrowForward } from 'react-icons/md'; // Import icons from react-icons
import styles from '../../styles/styles';
import EventCard from './EventCard';

const Events = () => {
  const { allEvents = [], isLoading } = useSelector((state) => state.events);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 4;

  const totalPages = Math.ceil(allEvents.length / eventsPerPage);

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = allEvents.slice(indexOfFirstEvent, indexOfLastEvent);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-40">
        <p>Loading...</p>
      </div>
    );
  }

  if (allEvents.length === 0) {
    return (
     <div className='max-w-screen dark:bg-[#1f1f1f] dark:text-gray-200'>
       <div className={`${styles.section} mb-2`}>
        <div className={`${styles.heading} text-sm mt-2 md:text-base lg:text-lg text-start`}>
          <h1>Scheduled Events</h1>
        </div>
        <h4 className="text-center mt-4 text-md mb-4 dark:text-gray-400">No Scheduled Events!</h4>
      </div>
     </div>
    );
  }

  return (
  <div className='max-w-screen dark:bg-[#1f1f1f] dark:text-gray-200'>
      <div className={`${styles.section} mb-2 mt-8`}>
      <div className={`${styles.heading} text-sm mt-2 md:text-base lg:text-lg text-start`}>
        <h1>Scheduled Events</h1>
      </div>

      <div className="overflow-x-auto mb-12 hide-scrollbar">
        <div className="flex gap-4">
          {currentEvents.length > 0 ? (
            currentEvents.map((event) => (
              <div key={event._id} className="min-w-[200px] md:min-w-[250px] lg:min-w-[300px]">
                <EventCard data={event} />
              </div>
            ))
          ) : (
            <h4 className="text-center mt-4">No Events Available!</h4>
          )}
        </div>
      </div>

      {totalPages > 1 && (
        <div className="flex justify-between items-center mt-2">
          <button 
            onClick={handlePreviousPage} 
            disabled={currentPage === 1} 
            className={`btn btn-primary flex items-center ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <MdArrowBack className="mr-2 text-[#29625D" />
            Previous
          </button>
          <span className='text-center text-[14px]'>{currentPage} / {totalPages}</span>
          <button 
            onClick={handleNextPage} 
            disabled={currentPage === totalPages} 
            className={`btn btn-primary flex items-center ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            Next
            <MdArrowForward className="ml-2 text-[#29625D" />
          </button>
        </div>
      )}
    </div>
  </div>
  );
};

export default Events;
