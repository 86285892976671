import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "./redux/store";
import { ThemeProvider } from "./context/ThemeContext";

ReactDOM.render(
  <ThemeProvider>
    <Provider store={Store}>
      <App />
    </Provider>,
  </ThemeProvider>,
  document.getElementById("root")
);

reportWebVitals();
