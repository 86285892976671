import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductList from "../components/Route/ProductList/ProductList";
import DropDownFilter from "../components/Layout/DropDownFilter";
import { categoriesData } from "../static/data"; // Import your categories data
import { RiEqualizerLine } from "react-icons/ri";
import { Helmet } from "react-helmet";
const ProductsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const searchTerm = searchParams.get("search") || ""; // Extract search term
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility

  // New filter states
  const [priceRange, setPriceRange] = useState([1, 10000000]);
  const [condition, setCondition] = useState("");
  const [isWholesale, setIsWholesale] = useState(false);
  const [isFlashSale, setIsFlashSale] = useState(false);
  const [isDailyDeal, setIsDailyDeal] = useState(false);
  const [isPriceFiltered, setIsPriceFiltered] = useState(false); // Track if price filter has been adjusted

  useEffect(() => {
    if (isLoading) return; // Prevent running while loading
    if (!Array.isArray(allProducts)) {
      console.error("allProducts is not an array");
      return;
    }

    const filteredData = allProducts.filter((product) => {
      const matchesCategory = categoryData
        ? product.category === categoryData
        : true;
      const matchesSearch =
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.tags.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesPrice =
        !isPriceFiltered ||
        (product.discountPrice >= priceRange[0] &&
          product.discountPrice <= priceRange[1]);

      return matchesCategory && matchesSearch && matchesPrice;
    });

    setData(filteredData);
  }, [
    allProducts,
    categoryData,
    searchTerm,
    isLoading,
    priceRange,
    isPriceFiltered,
  ]);

  const handleCategoryChange = (category) => {
    const newParams = new URLSearchParams(searchParams);
    if (category === categoryData) {
      newParams.delete("category"); // Remove category if it's already selected
    } else {
      newParams.set("category", category); // Set the new category
    }
    setSearchParams(newParams); // Update the URL parameters
  };

  const handlePriceChange = (newRange) => {
    setPriceRange(newRange);
    setIsPriceFiltered(true); // Mark that the price filter has been adjusted

    const newParams = new URLSearchParams(searchParams);
    newParams.set("priceMin", newRange[0]); // Add or update min price
    newParams.set("priceMax", newRange[1]); // Add or update max price
    setSearchParams(newParams); // Update the URL parameters
  };

  const handleConditionChange = (condition) => {
    const newParams = new URLSearchParams(searchParams);
    if (condition) {
      newParams.set("condition", condition); // Set condition if it exists
    } else {
      newParams.delete("condition"); // Remove condition if it's empty
    }
    setCondition(condition);
    setSearchParams(newParams); // Update the URL parameters
  };

  const handleWholesaleChange = () => {
    const newWholesaleState = !isWholesale;
    setIsWholesale(newWholesaleState);

    const newParams = new URLSearchParams(searchParams);
    newParams.set("wholesale", newWholesaleState); // Update wholesale filter
    setSearchParams(newParams); // Update the URL parameters
  };

  const handleFlashSaleChange = () => {
    const newFlashSaleState = !isFlashSale;
    setIsFlashSale(newFlashSaleState);

    const newParams = new URLSearchParams(searchParams);
    newParams.set("flashSale", newFlashSaleState); // Update flash sale filter
    setSearchParams(newParams); // Update the URL parameters
  };

  const handleDailyDealChange = () => {
    const newDailyDealState = !isDailyDeal;
    setIsDailyDeal(newDailyDealState);

    const newParams = new URLSearchParams(searchParams);
    newParams.set("dailyDeal", newDailyDealState); // Update daily deal filter
    setSearchParams(newParams); // Update the URL parameters
  };

  //SEO products indexing

  const productList = [
    {
      name: "Google Pixel 6",
      description:
        "The Google Pixel 6 is an innovative smartphone that combines cutting-edge technology, premium design, and exceptional performance. Powered by Google’s custom Tensor processor, the Pixel 6 delivers smooth performance, AI-driven features, and enhanced security—making it a perfect choice for those seeking a device that’s fast, smart, and secure. The 6.4-inch AMOLED display offers stunning visuals with rich colors and sharp detail, while the dual-camera system takes your photography to the next level. Whether you’re capturing the perfect shot or multitasking, the Pixel 6 is designed to keep up with your busy life.",
      price: "260,000 RWF",
      url: "https://guriraline.com/product/6737694fba5952d4be3c08c4",
    },
    {
      name: "Switzerland Nesun Women's Watches Luxury",
      description:
        "New Switzerland Nesun Women's Watches Luxury Brand Quartz Watch Women Six-leaf grass design Clock Diamond Wristwatches N9065-4",
      price: "105,000 RWF",
      url: "https://guriraline.com/product/67493fbdac42e5077b3e6adc",
    },
    {
      name: "Promot Rubber Wristband for adults (Multiple colors)",
      description:
        "Promot Merch Rubber Wristband. Designed for adults, this durable, flexible wristband offers a simple yet impactful way to share your message, whether for charity events, brand promotions, or team spirit. Lightweight and comfortable, it’s perfect for everyday wear, making it easy to show off your cause wherever you go.",
      price: "1,000 RWF",
      url: "https://guriraline.com/product/673e099bc4616e4cffdab559",
    },
    {
      name: "Promot T-shirt (Promot Merch -All sizes) for Men, women and Children",
      description:
        "Promot T-Shirt, a high-quality and stylish garment designed for comfort and durability. Whether you're promoting your business, event, or cause, this t-shirt is the perfect choice to make your message stand out. Crafted with care and attention to detail, the Promot T-Shirt offers a perfect blend of style, comfort, and flexibility for any occasion.",
      price: "12,000 RWF",
      url: "https://guriraline.com/product/673df8435b00468530178095",
    },
    // Add more products...
  ];
  
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Guriraline Products",
    "description": "Browse a wide selection of products on Guriraline.",
    "offers": productList.map(product => ({
      "@type": "Offer",
      "priceCurrency": "RWF", // Assuming RWF is the currency
      "price": product.price,
      "url": product.url,
      "itemOffered": {
        "@type": "Product",
        "name": product.name,
        "description": product.description
      }
    }))
  };
  
  // Optionally, you can stringify the structured data if you want to insert it directly into your HTML
  const structuredDataString = JSON.stringify(structuredData);
  
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
        <Helmet>
        <title>Products | Guriraline</title>
        <meta name="description" content="Browse a wide range of products at Guriraline." />
        <script type="application/ld+json">{JSON.stringify(structuredDataString)}</script>
      </Helmet>
        <div className="bg-white dark:bg-[#1f1f1f] dark:text-gray-200 min-h-screen">
          <Header activeHeading={3} />
          <div className="flex justify-center items-start flex-wrap w-full mb-10">
            {/* Filter Sidebar for Desktop */}
            <div className="hidden lg:block w-[20%] p-6 mx-2 lg:mx-0">
              <h2 className="font-bold text-lg mb-4 text-gray-800 dark:text-white">
                Filter Options
              </h2>

              {/* Category Filter */}
              <div className="mb-6">
                <h3 className="font-semibold text-black mb-2 dark:text-gray-200">Category</h3>
                <div className="flex flex-col">
                  {categoriesData.map((category) => (
                    <label
                      key={category.id}
                      className="flex items-center mb-2 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        checked={categoryData === category.title}
                        onChange={() => handleCategoryChange(category.title)}
                        className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-[#1f1f1f]"
                      />
                      <span className="text-gray-600 dark:text-gray-200 text-[14px]">
                        {category.title}
                      </span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Price Range Filter */}
              <div className="mb-6">
                <h3 className="font-semibold text-gray-700 dark:text-gray-200 dark:bg-[#1f1f1f]">Price Range</h3>
                <input
                  type="range"
                  min="0"
                  max="10000000"
                  value={priceRange[0]}
                  onChange={(e) =>
                    handlePriceChange([+e.target.value, priceRange[1]])
                  }
                  className="range-input w-full mb-2"
                />
                <input
                  type="range"
                  min="0"
                  max="10000000"
                  value={priceRange[1]}
                  onChange={(e) =>
                    handlePriceChange([priceRange[0], +e.target.value])
                  }
                  className="range-input w-full mb-4"
                />
                <p className="text-black text-[14px] dark:text-gray-200">
                  Selected Price: RWF {priceRange[0]} - RWF {priceRange[1]}
                </p>
              </div>

              {/* Condition Filter */}
              <div className="mb-6">
                <h3 className="font-semibold text-gray-700 dark:text-gray-200 dark:bg-[#1f1f1f]">Condition</h3>
                <select
                  value={condition}
                  onChange={(e) => handleConditionChange(e.target.value)}
                  className="w-full mb-2 border rounded-md shadow-sm dark:text-gray-200 dark:bg-[#1f1f1f]"
                >
                  <option value="">All Conditions</option>
                  <option value="new">New</option>
                  <option value="used">Used</option>
                </select>
              </div>

              {/* Wholesale Checkbox */}
              <div className="mb-6">
                <label className="flex items-center mb-2 cursor-pointer ">
                  <input
                    type="checkbox"
                    checked={isWholesale}
                    onChange={handleWholesaleChange}
                    className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <span className="text-gray-600 text-[14px]">Wholesale</span>
                </label>
              </div>

              {/* Flash Sale Checkbox */}
              <div className="mb-6">
                <label className="flex items-center mb-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isFlashSale}
                    onChange={handleFlashSaleChange}
                    className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <span className="text-gray-600 text-[14px]">Flash Sale</span>
                </label>
              </div>

              {/* Daily Deal Checkbox */}
              <div className="mb-6">
                <label className="flex items-center mb-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isDailyDeal}
                    onChange={handleDailyDealChange}
                    className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <span className="text-gray-600 text-[14px]">Daily Deal</span>
                </label>
              </div>
            </div>

            {/* Button to toggle Dropdown Filter for Mobile */}
            <div className="block lg:hidden w-full p-4">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="bg-[#29625d] text-white py-2 px-4 rounded"
              >
                <RiEqualizerLine />
              </button>
              {dropdownOpen && (
                <DropDownFilter
                  categoryData={categoryData}
                  handleCategoryChange={handleCategoryChange}
                />
              )}
            </div>

            {/* Product List Area */}
            <div className="flex-grow w-full lg:w-[70%] p-4 mx-2 lg:mx-0">
              {data && data.length > 0 ? (
                <ProductList products={data} />
              ) : (
                <h1 className="text-center w-full pb-[100px] text-[20px]">
                  No products Found!
                </h1>
              )}
            </div>
          </div>
          <Footer />
        </div>
        </div>
      )}
    </>
  );
};

export default ProductsPage;
