import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { brandingData, categoriesData } from "../../../static/data";
import styles from "../../../styles/styles";
import MobileCategories from "./MobileCategories";

const Categories = () => {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900);
    };

    handleResize(); // Call once to set initial state
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isSmallScreen) {
    return <MobileCategories />;
  }
  return (
    <div className="bg-transparent dark:bg-[#1f1f1f] max-w-screen">
      <div className={`${styles.section} hidden sm:block`}>
        <div
          className={`branding border p-2 my-12 flex justify-between w-full shadow-sm bg-white dark:bg-black dark:text-gray-200 p-5 rounded-md`}
        >
          {brandingData &&
            brandingData.map((i, index) => (
              <div className="flex items-start" key={index}>
                {i.icon}
                <div className="px-3">
                  <h3 className="font-bold text-sm md:text-base">{i.title}</h3>
                  <p className="text-xs md:text-sm">{i.Description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        className={`${styles.section} bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-200 p-6 rounded-lg mb-12`}
        id="categories"
      >
        <div className="grid grid-cols-1 gap-[5px] md:grid-cols-2 md:gap-[10px] lg:grid-cols-4 lg:gap-[20px] xl:grid-cols-5 xl:gap-[30px]">
          {categoriesData &&
            categoriesData.map((i) => {
              const handleSubmit = (i) => {
                navigate(`/products?category=${i.title}`);
              };
              return (
                <div
                  className="w-[90%] h-[100px] mr-auto ml-auto  cursor-pointer overflow-hidden"
                  key={i.id}
                  onClick={() => handleSubmit(i)}
                >
                  
                  <img
                    src={i.image_Url}
                    className="w-[80px] bg-white mr-auto ml-auto rounded-full object-cover"
                    alt=""
                  />
                  <h5 className={`text-md mt-1 text-center  leading-[1.3]`}>{i.title}</h5>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Categories;
