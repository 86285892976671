import React from 'react'
import ResetPassword from '../components/Login/ResetPassword';
const ResetPasswordPage = () => {
  return (
    <div>
        <ResetPassword/>
    </div>
  )
}

export default ResetPasswordPage